<template>
  <v-navigation-drawer right class="navigation-drawer pa-3" :value="dialog" app temporary>
    <template v-slot:prepend>
      <v-toolbar>
        <v-toolbar-title></v-toolbar-title>
        <v-spacer></v-spacer>
        <v-icon @click="dialog = !dialog"> mdi-close </v-icon>
      </v-toolbar>
    </template>
    <template v-slot:default>
      <v-img class="white" :src="invoiceImg"></v-img>
    </template>

    <template v-slot:append>
      <v-toolbar>
        <v-spacer></v-spacer>
        <v-btn color="success"> Imprimir </v-btn>
      </v-toolbar>
    </template>
  </v-navigation-drawer>
</template>

<script>
//import { fe_ws } from "../services/webserver.js";
import createDoc from "../utils/create_doc.js";
//import dianResponse from "../utils/dianResponse.js";
//import { webserver } from "../services/webserver.js";
import settings from "../utils/settings.js";
import jsPDF from "jspdf";
export default {
  name: "docViewer",
  props: {
    item: {
      type: Object,
      required: true,
      default: createDoc(),
    },
    dialog: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  mounted() {
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  data() {
    return {
      setting: settings(),
      invoiceImg: null,
      dian_dialog: false,
      docview: "",
      loading_status: false,
      pdfFile: null,
    };
  },
  methods: {
    onResize() {
      this.best_height = window.innerHeight;
      if (window.innerWidth < 480) {
        this.best_width = window.innerWidth;
      } else {
        this.best_width = window.innerWidth / 2;
      }
    },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },
    print_canvas(data) {
      console.log("print_canvas", this.item);
      var LS = 50;
      var itemsQty = data.items.length * LS;
      var elem = document.createElement("canvas");
      elem.width = 945;
      elem.height = 1800 + itemsQty;
      // var total = 0;
      var center = elem.width / 2;
      // var left = 10;
      var line = 150;
      //var right = elem.width - left;
      const ctx = elem.getContext("2d");

      var items = data.items;
      items.forEach((item) => {
        item.quantity = parseInt(item.quantity);
        item.price = parseInt(item.price);
        item.discount = parseInt(item.discount);
      });
      // var regimen = "NO RESPONSABLES DEL IVA";

      //   if (data.store.TaxLevelCode) {
      //     switch (data.store.TaxLevelCode) {
      //       case "0":
      //         regimen = "NO RESPONSABLES DEL IVA";
      //         break;
      //       case "1":
      //         regimen = "RESPONSABLES DEL IVA";
      //         break;
      //       default:
      //         regimen = "NO RESPONSABLES DEL IVA";
      //     }
      //   }

      ctx.font = "bold 40px Arial";
      ctx.textAlign = "center";

      ctx.font = "bold 60px Arial";
      ctx.fillText(data.store.PartyIdentification_Id, center, line);
      line += LS;
      ctx.font = "bold 40px Arial";
      ctx.fillText(data.store.PartyName, center, line);
      line += LS;
      ctx.fillText("Nit : " + data.store.PartyIdentification, center, line);
      //   line += LS;
      //   ctx.fillText(regimen, center, line);
      line += LS;
      ctx.fillText(data.store.AddressLine + ", " + data.store.CityName, center, line);
      line += LS;
      // ctx.fillText("Resolución DIAN: " + data.permit.InvoiceAuthorization, center, line);
      // line += LS;
      // ctx.fillText(
      //   "Del: " +
      //     data.permit.Prefix +
      //     "-" +
      //     data.permit.AuthorizationFrom +
      //     " al " +
      //     data.permit.Prefix +
      //     "-" +
      //     data.permit.AuthorizationTo,
      //   center,
      //   line
      // );
      // line += LS;
      // ctx.fillText(
      //   "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
      //   center,
      //   line
      // );
      line += LS;
      //   ctx.font = "bold 35px Arial";
      //   ctx.fillText("Documento Equivalente a la Factura de Venta", center, line);
      //   ctx.font = "bold 40px Arial";
      //   line += LS;
      //   ctx.fillText("No. " + data.header.invoice, center, line);
      //   line += LS;
      //   ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
      //   line += LS;
      //   ctx.fillText("NO RETENEDORES DE IVA", center, line);
      //   line += LS;
      //   ctx.fillText("Sistema POS", center, line);
      //   line += LS;
      //   if (data.payments[0].hora) {
      //     ctx.fillText(data.header.issueDate + " " + data.payments[0].hora, center, line);
      //   } else {
      //     ctx.fillText(data.header.issueDate, center, line);
      //   }

      //   line += LS;
      //   ctx.textAlign = "start";
      //   ctx.fillText("C.C./Nit : " + data.header.PartyIdentification, left, line);
      //   line += LS;
      //   ctx.fillText("Nombre    : " + data.header.PartyName, left, line);
      //   line += LS;
      //   line += LS;
      //   LS = 60;
      //   ctx.font = "bold 50px Arial";
      //   ctx.textAlign = "center";
      //   ctx.fillText("Productos", center, line);
      //   line += LS;
      //   ctx.beginPath();
      //   ctx.moveTo(left, line - 10);
      //   ctx.lineTo(right, line - 10);
      //   ctx.stroke();
      //   line += LS;
      //   total = 0;
      //   var tdiscount = 0;
      //   items.forEach((row) => {
      //     console.log(row);
      //     var price = row.price * row.quantity;
      //     var discount = row.discount * row.quantity;
      //     ctx.textAlign = "start";
      //     ctx.fillText(row.quantity, left, line);
      //     ctx.textAlign = "start";
      //     ctx.fillText(row.description, left + 50, line);
      //     ctx.textAlign = "end";
      //     ctx.fillText(price.toLocaleString(), right, line);
      //     line += LS;
      //     total += price;
      //     tdiscount += discount;
      //   });
      //   ctx.beginPath();
      //   ctx.moveTo(left, line - 10);
      //   ctx.lineTo(right, line - 10);
      //   ctx.stroke();
      //   line += LS;
      //   var tax_value = 1.19;
      //   var sin_iva = Math.round(total / tax_value);
      //   var iva = total - sin_iva;
      //   ctx.textAlign = "start";
      //   ctx.fillText("Base gravable", left, line);
      //   ctx.textAlign = "end";
      //   ctx.fillText(sin_iva.toLocaleString(), right, line);
      //   line += LS;
      //   ctx.textAlign = "start";
      //   ctx.fillText("IVA " + Math.round((tax_value - 1) * 100) + "%", left, line);
      //   ctx.textAlign = "end";
      //   ctx.fillText(iva.toLocaleString(), right, line);
      //   line += LS;
      //   ctx.textAlign = "start";
      //   ctx.fillText("TOTAL", left, line);
      //   ctx.textAlign = "end";
      //   ctx.fillText(total.toLocaleString(), right, line);
      //   line += LS;

      //   if (tdiscount > 0) {
      //     ctx.textAlign = "start";
      //     ctx.fillText("Descuento aplicado", left, line);
      //     ctx.textAlign = "end";
      //     ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
      //     line += LS;
      //   }
      //   line += LS;

      //   ctx.textAlign = "center";
      //   ctx.fillText("Formas de pago", center, line);
      //   line += LS;
      //   // console.log(data.payments);
      //   // data.payments.forEach((row) => {
      //   //   console.log(row);
      //   //   ctx.textAlign = "start";
      //   //   ctx.fillText(row.tipo + " " + row.comprobante, left, line);
      //   //   ctx.textAlign = "end";
      //   //   ctx.fillText(row.valor.toLocaleString(), right, line);
      //   //   line += LS;
      //   // });
      //   line += LS;
      //   ctx.textAlign = "center";
      //   // if (window.store.store_footer) {
      //   //   var res = window.store.store_footer.split("<br>");
      //   //   res.forEach((item) => {
      //   //     ctx.fillText(item, center, line);
      //   //     line += LS;
      //   //   });
      //   // } else {
      //   ctx.fillText("! Gracias por su compra !", center, line);
      //   // }
      //   line += LS;
      //   line += LS;
      //   line += LS;

      //   line += LS;
      //   line += LS;

      this.dataURL = elem.toDataURL();
      this.invoiceImg = this.dataURL;
      //   this.showPrint = true;
    },
    print_invoice(doc) {
      console.log("----", doc);
      if (doc.header) {
        var pdf = new jsPDF({
          orientation: "p",
          unit: "mm",
          format: "letter",
        });
        var LS = 5;
        var y = 20;
        var x = 20;

        pdf.setFontType = "bold";
        pdf.setFontSize(14);
        pdf.text(20, y, "NGM Colombia SAS", "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(
          20,
          y,
          this.id_lst.find((item) => item.code == doc.store.type_id).name +
            " " +
            doc.store.identification_id,
          "left"
        );
        y += LS;
        pdf.text(20, y, doc.store.address, "left");
        y += LS;
        pdf.text(20, y, doc.store.city + ", " + doc.store.province, "left");
        y = 48;

        pdf.setFontSize(8);
        pdf.text(
          20,
          y,
          this.docType.find((item) => item.value == doc.header.doc_type).label +
            " : " +
            doc.header.doc_code,
          "left"
        );
        pdf.text(160, y, "Fecha: " + doc.header.issue_date, "left");
        y += LS;
        pdf.text(160, y, "Estado: " + doc.header.status, "left");
        y += LS;
        y += 2;
        pdf.setLineWidth(0.1);
        pdf.setDrawColor(0, 102, 0);
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;
        y = 20;
        pdf.setFontSize(14);
        pdf.text(120, y, doc.header.party_name, "left");
        pdf.setFontType = "normal";
        pdf.setFontSize(8);
        y += LS;
        pdf.text(120, y, doc.header.party_address, "left");
        y += LS;
        pdf.text(120, y, doc.header.party_city, "left");

        y += LS;
        pdf.text(
          120,
          y,
          doc.header.party_email + " Tel: " + doc.header.party_phone,
          "left"
        );
        y += LS;
        pdf.text(120, y, doc.header.party_note, "left");
        y += LS;
        y += LS;
        y = 60;
        pdf.setFontSize(10);

        pdf.setFontType = "normal";
        pdf.text(50, y, "Product", "center");
        pdf.text(130, y, "Qty", "center");
        pdf.text(150, y, "Pack", "center");
        pdf.text(170, y, "", "center");
        pdf.text(195, y, "Price", "center");
        // y += 2;
        // pdf.line(20, y, 200, y);
        var temY = y;
        pdf.roundedRect(15, temY - 5, 190, 140, 1, 1);
        doc.items.forEach((row) => {
          y += LS;
          pdf.setFontType = "bold";
          pdf.text(20, y, row.product_name);
          pdf.setFontType = "normal";
          pdf.text(130, y, row.qty.toLocaleString(), "center");
          pdf.text(
            150,
            y,
            parseFloat(row.pack).toLocaleString(2) + " " + row.uom,
            "center"
          );
          pdf.text(
            170,
            y,
            (parseFloat(row.qty) * parseFloat(row.pack)).toLocaleString(2) +
              " " +
              row.uom,
            "center"
          );
          pdf.text(200, y, "$ " + parseFloat(row.tItem).toLocaleString(2), "right");
          y += LS;
          var dtext = row.description.replace(/<[^>]+>/g, "");
          var splitTitle = pdf.splitTextToSize(dtext, 105);
          pdf.text(20, y, splitTitle);
          y += LS;
          //pdf.text(x, y, dtext);
        });

        y = 200;
        temY = y;
        pdf.setFillColor(255, 255, 200);
        pdf.roundedRect(15, y - 2, 190, 20, 1, 1);

        doc.payments.forEach((row) => {
          y += LS;
          x = 20;
          pdf.text(x, y, row.payment_concept, "left");
          x = 120;
          pdf.text(x, y, "$ " + parseFloat(row.payment_value).toLocaleString(2), "right");
          y += LS;
          x = 30;
          if (row.description) {
            var dtext = row.description.replace(/<[^>]+>/g, "");
            var splitTitle = pdf.splitTextToSize(dtext, 150);
            pdf.text(x, y, splitTitle);
            y += LS;
          }
        });
        y = temY;

        y += LS;
        x = 150;
        pdf.setFontType = "bold";
        pdf.text(x, y, "Sub-Total");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.total).toLocaleString(2), "right");
        y += LS;
        x = 150;
        pdf.text(x, y, "Impuesto");
        x = 200;
        pdf.text(x, y, "$ " + parseFloat(doc.header.tax).toLocaleString(2), "right");
        y += LS;
        x = 150;
        pdf.text(x, y, "TOTAL");
        x = 200;
        pdf.text(
          x,
          y,
          "$ " +
            (parseFloat(doc.header.total) + parseFloat(doc.header.tax)).toLocaleString(2),
          "right"
        );
        // y += 2;
        // pdf.line(20, y, 200, y);
        y += LS;
        y += LS;

        temY = y;
        pdf.setFontSize(8);
        var note = pdf.splitTextToSize(
          "El presente documento presta merito ejecutivo y se asemeja a una letra de cambio.",
          85
        );
        pdf.text(115, y, note);

        pdf.text(20, y, "Observaciones");
        note = pdf.splitTextToSize(doc.header.note, 85);
        y += LS;
        pdf.text(20, y, note);
        // pdf.setFontSize(8);
        y += LS;
        y += LS;
        y += LS;
        y += LS;
        // y += 2;
        // pdf.line(40, y, 120, y);
        y += LS;

        pdf.text(115, y, "ACEPTO : " + doc.header.party_name);
        y += LS;
        pdf.text(
          115,
          y,
          this.id_lst.find((item) => item.code == doc.header.party_type_id).name +
            ": " +
            doc.header.party_identification_id
        );
        pdf.roundedRect(112, temY - 5, 93, y - temY + 8, 1, 1);

        pdf.roundedRect(15, temY - 5, 93, y - temY + 8, 1, 1);

        pdf.setTextColor(0, 183, 0);
        var today = new Date();
        pdf.text(10, 100, "Date Printed : " + today.toLocaleString(), null, 90);
        var out = pdf.output();
        var url = "data:application/pdf;base64," + btoa(out);
        this.pdfFile = url;
        //this.pdfFile = pdf.output('datauri');

        var pdfName = doc.header.doc_code;
        pdf.save(pdfName + "_" + doc.header.party_name + ".pdf");
      }
    },
  },
  watch: {
    item: function (e) {
      //this.print_canvas(e);
      this.print_invoice(e);
    },
  },
};
</script>
